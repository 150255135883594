.box-outlined {
  border: 1px solid $secondary;
  border-radius: 8px;
  padding: 15px;
}

.box-app-version {
  font-size: 10px;
  position: fixed;
  left: 10px;
  bottom: 10px;
  background-color: #fff;
  padding: 3px;
  z-index: 9999;
}

// ---------- CARD --------------
.card {
  border: none;
  border-radius: 4px;
}
// ---------- CARD --------------

// Contenedor para la foto del estudiante
.image-square-container {
  background-color: $bg-gray-100;
}
.image-square {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  border-radius: 4px;
  img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.text-primary {
  color: $primary;
}
