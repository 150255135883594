.label {
  text-align: center;
  font: normal normal normal 16px/30px Source Sans Pro;
  letter-spacing: 0px;
  color: #1e1e1e;
  margin-bottom: 0;
}

.fileContainer {
  padding: 11px;
  background-color: rgba(247, 247, 247, 0.59);
  min-height: 47px;
  display: flex;
  align-items: center;
}

.fileName {
  font: normal normal normal 14px/18px Source Sans Pro;
  letter-spacing: 0px;
  color: #838383;
  padding: 0px 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}
