.g-navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  min-height: 75px;
  background-color: $primary;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1030;

  .g-brand-logo {
    img {
      width: auto !important;
      max-height: 50px !important;
    }
  }

  .g-items-container {
    align-self: center;
    .g-item {
      display: inline-flex;
      padding: 10px;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        background-color: $secondary;
      }

      .g-icon {
        path {
          fill: white !important;
        }
      }

      span {
        color: white;
        font-size: 14px;
      }
    }
  }
}
